

import { Vue, Component, Watch } from 'vue-property-decorator'
import { ProjectDetail } from '@/types/onlineStatistics'
import { Page } from '@/types/common'
import { dateBefore } from '@/utils/formatDate'
import echarts from 'echarts'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'jobStatistics'
})
@KeepAlive
export default class OnlineStatistics extends Vue {
  page = 1
  size = 10
  total = 0
  loading = false
  tableData: ProjectDetail[] = []
  activeName = '1'
  searchForm = {
    projectName: '',
    startTime: '',
    endTime: ''
  }

  pickMinTime: any = null
  chart: any = null
  dateRange: Array<string> = []
  @Watch('dateRange')
  dateRangeChange (value: string[]) {
    value = value || []
    this.searchForm.startTime = value[0]
    this.searchForm.endTime = value[1]
  }

  // 日期选择当天之前
  pickerOptions: any = {
    onPick: (date: {maxDate: string; minDate: any}) => {
      this.pickMinTime = date.minDate.getTime()
    },
    // 限制范围最大一个月且在今天之前的日期
    disabledDate: (time: any) => {
      if (this.pickMinTime) {
        const one = 30 * 24 * 3600 * 1000
        const minTime: number = this.pickMinTime - one
        const maxTime: number = this.pickMinTime + one
        return time.getTime() > (Date.now() - 24 * 60 * 60 * 1000) || (time.getTime() < minTime || time.getTime() > maxTime)
      } else {
        return time.getTime() > new Date().getTime() - 24 * 60 * 60 * 1000
      }
    }
  }

  initTime () {
    this.dateRange = dateBefore(30, false)
    this.searchForm.startTime = this.dateRange[0]
    this.searchForm.endTime = this.dateRange[1]
  }

  created () {
    this.initTime()
    this.loadData()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  resize () {
    this.chart.resize()
  }

  loadData () {
    this.loading = true
    this.$axios.get<Page<ProjectDetail>>(this.$apis.bracelet.selectWorkerDateProjectByPage, {
      ...this.searchForm,
      page: this.page,
      size: this.size
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  getDraw () {
    this.$axios.get(this.$apis.bracelet.selectWorkerDateByMap, this.searchForm).then(res => {
      this.draw(res)
    })
  }

  getDraw2 (time: string) {
    this.$axios.get(this.$apis.bracelet.selectWorkerDateProjectByMap, {
      dateTime: time,
      projectName: this.searchForm.projectName
    }).then(res => {
      if (res.ruleNum.length) {
        this.draw2({
          ...res,
          name: time
        })
      }
    })
  }

  radioChange () {
    this.activeName === '2' && this.getDraw()
  }

  draw (data: {dateTime: string[]; ruleNum: string[]; allNum: string[]}) {
    this.$nextTick(() => {
      this.chart = echarts.init(this.$refs.chart as any)
      const option: any = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0)',
          enterable: true,
          renderMode: 'html',
          triggerOn: 'click',
          alwaysShowContent: false,
          transitionDuration: 1,
          axisPointer: {
            type: 'line'
          },
          formatter: (params: any) => {
            this.getDraw2(params[0].axisValue)
            return '<div id="chart2" style="width:475px; height:280px;box-shadow: 0px 0px 6px 0px rgba(143, 143, 143, 0.46);" ref="chart2" ></div>'
          }
        },
        title: {
          show: true,
          top: '0',
          textAlign: 'left',
          textStyle: {
            color: 'rgba(71, 71, 71, 1)',
            fontSize: 16,
            fontWeight: '500'
          }
        },
        legend: {
          show: false
        },
        grid: {
          left: 50,
          right: 40,
          bottom: 30,
          top: 50
        },
        xAxis: [{
          type: 'category',
          data: data.dateTime,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(151, 151, 151, 1)',
              width: 1
            }
          },
          axisTick: {
            show: true
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'rgba(42, 42, 42, 1)',
              fontSize: 16
            }
          }
        }],
        yAxis: [{
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: 'rgba(42, 42, 42, 1)',
            fontSize: 16
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(231, 228, 228, 1)'
            }
          }
        }],
        series: [{
          name: '在岗人数',
          type: 'line',
          data: data.ruleNum,
          smooth: true,
          symbolSize: 8,
          itemStyle: {
            normal: {
              color: 'rgba(38, 131, 246, 1)',
              width: 2,
              opacity: 1
            }
          }
        },
        {
          name: '总人数',
          type: 'line',
          data: data.allNum,
          smooth: true,
          symbolSize: 8,

          itemStyle: {
            normal: {
              color: 'rgba(26, 209, 176, 1)',
              width: 2,
              opacity: 1
            }
          }
        }]
      }
      this.chart.setOption(option)
      this.chart.dispatchAction({
        type: 'showTip',
        seriesIndex: 1, // 显示第几个series
        dataIndex: data.dateTime.length - 1 // 显示第几个数据
      })
    })
  }

  draw2 (data: {projectName: string[]; ruleNum: string[]; allNum: string[]; name: string}) {
    const chart = echarts.init(document.getElementById('chart2') as any)
    const option: any = {
      backgroundColor: '#fff',
      tooltip: {
        trigger: 'axis',
        backgroundColor: '#0A354E',
        borderColor: '#378BA1',
        borderWidth: 1,
        axisPointer: {
          type: 'shadow'
        }
      },
      title: {
        show: true,
        text: data.name + '各项目工人在岗统计',
        top: '26',
        left: '130',
        textStyle: {
          color: 'rgba(71, 71, 71, 1)',
          fontSize: 16,
          fontWeight: '500'
        }
      },
      legend: {
        show: false
      },
      grid: {
        left: 40,
        right: 40,
        bottom: 35,
        top: 77
      },
      dataZoom: [{
        type: 'slider',
        show: true,
        bottom: 0,
        height: 10,
        startValue: 0,
        endValue: 2, // 初始化滚动条
        borderColor: 'transparent',
        backgroundColor: '#fff',
        fillerColor: '#ccc',
        handleStyle: {
          opacity: 0
        },
        showDetail: false,
        showDataShadow: false,
        brushSelect: false
      }],
      xAxis: [{
        type: 'category',
        data: data.projectName,
        axisLine: {
          show: true,
          lineStyle: {
            color: 'rgba(151, 151, 151, 1)',
            width: 1
          }
        },
        axisTick: {
          show: true
        },
        axisLabel: {
          show: true,
          formatter: (value: string) => {
            if (value.length > 8) {
              return `${value.substring(0, 8)}...`
            } else {
              return value
            }
          },
          textStyle: {
            color: 'rgba(71, 71, 71, 1)',
            fontSize: 12
          }

        }
      }],
      yAxis: [{
        type: 'value',
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        axisLabel: {
          color: 'rgba(71, 71, 71, 1)',
          fontSize: 12
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: 'rgba(237, 237, 237, 1)'
          }
        }
      }],
      series: [{
        name: '在岗人数',
        type: 'bar',
        data: data.ruleNum,
        barMinHeight: 3,
        barMaxWidth: 12, // 柱子宽度
        barGap: 0, // 柱子之间间距
        itemStyle: {
          normal: {
            color: 'rgba(80, 254, 187, 1)',
            opacity: 1
          }
        }
      }, {
        name: '人员总数',
        type: 'bar',
        data: data.allNum,
        barMinHeight: 3,
        barMaxWidth: 12, // 柱子宽度
        barGap: 0, // 柱子之间间距
        itemStyle: {
          normal: {
            color: 'rgba(104, 228, 255, 1)',
            opacity: 1
          }
        }
      }]
    }
    chart.setOption(option)
  }

  onSearch () {
    this.page = 1
    this.loadData()
    this.getDraw()
  }

  onDetail (projectId: string, dateTime: string) {
    this.$router.push('/onlineStatistics/detail?projectId=' + projectId + '&dateTime=' + dateTime)
  }

  onSetting () {
    this.$router.push('/onlineStatistics/rulesettings')
  }

  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.bracelet.exportWorkerProjectDate, this.searchForm).finally(() => {
      loading.close()
    })
  }
}
